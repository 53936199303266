<!-- ts schedule start-->
<section class="ts-schedule">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title">Exhibition</h2>
      </div>
    </div>
    <!-- row end-->
    <div class="row">
      <!-- <a-scene embedded style="height: 700px; width: 100%"> -->
      <!-- Start Scene -->
      <!-- renderer="antialias: true" -->
      <!-- -->
      <a-scene
        vr-mode-ui="enabled: false"
        loading-screen="dotsColor: red; backgroundColor: #fff ;enabled:true"
        background="color: #fff"
        fog="  far:  200;  density:  0;  type:  exponential"
        environment
      >
        <!-- <a-entity
          environment="preset: forest; groundColor: #445; grid: cross"
        ></a-entity> -->

        <!-- -->
        <!-- -->
        <!--  -->
        <!--  -->
        <!-- <a-entity environment="preset: poison"></a-entity> -->

        <a-assets (loaded)="loaded($event)">
          <a-asset-item id="hall" [attr.src]="hall"></a-asset-item>

          <a-asset-item
            id="zimmer_booth"
            [attr.src]="zimmer_booth"
          ></a-asset-item>
          <a-asset-item
            id="amgen_booth"
            [attr.src]="amgen_booth"
          ></a-asset-item>
          <a-asset-item
            id="lilly_booth"
            [attr.src]="lilly_booth"
          ></a-asset-item>
          <a-asset-item
            id="smith_booth"
            [attr.src]="smith_booth"
          ></a-asset-item>
          <a-asset-item
            id="medpharma_booth"
            [attr.src]="medpharma_booth"
          ></a-asset-item>
          <a-asset-item
            id="pfizer_booth"
            [attr.src]="pfizer_booth"
          ></a-asset-item>
          <a-asset-item
            id="depuy_booth"
            [attr.src]="depuy_booth"
          ></a-asset-item>
          <a-asset-item
            id="amico_booth"
            [attr.src]="amico_booth"
          ></a-asset-item>
          <a-asset-item
            id="stryker_booth"
            [attr.src]="stryker_booth"
          ></a-asset-item>

          <!-- <img
            id="here"
            scr="assets/event/exh/3d/giphy.gif"
            crossorigin="anonymous"
          /> -->
        </a-assets>
        <!-- <a-image src="#here"></a-image> -->
        <a-entity *ngIf="isLoaded" gltf-model="#hall" position="0 1 -15">
          <!-- stryker_booth -->
          <a-entity link="href: ./exh-stryker">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#stryker_booth"
              position="-15.6 0 -13.7"
              rotation=" 0 0 0"
            >
            </a-entity>
          </a-entity>

          <!-- zimmer_booth -->
          <a-entity link="href: ./exh-zimmer">
            <!-- for link  -->
            <a-box
              width="9"
              height="6"
              depth="5"
              visible="false"
              position="0 0 -15"
            ></a-box>
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#zimmer_booth"
              position="-0.3 0 -14"
            >
            </a-entity>
          </a-entity>
          <!-- smith_booth -->
          <a-entity link="href: ./exh-smith-and-nephew">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#smith_booth"
              position="12 0 -13"
              rotation="0 0 0"
              scale="1.3 1.3 1.3"
            >
            </a-entity>
          </a-entity>

          <!-- pfizer_booth -->
          <a-entity link="href: ./exh-pfizer">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#pfizer_booth"
              position="-22 0 -5.5"
              rotation="0 90 0"
              scale="1.3 1.3 1.3"
            >
            </a-entity>
          </a-entity>

          <!-- amgen_booth right first -->
          <a-entity link="href: ./exh-amgen">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#amgen_booth"
              position="24.2 0 10.8"
              rotation="0 90 0"
            >
            </a-entity>
          </a-entity>
          <!-- lilly_booth -->
          <a-entity link="href: ./exh-lilly">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#lilly_booth"
              position="-22.5 .2 8"
              rotation="0 90 0"
              scale="1.2 1.2 1.2"
            >
            </a-entity>
          </a-entity>

          <!-- medpharma_booth -->
          <a-entity link="href: ./exh-medpharma">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#medpharma_booth"
              position="22.6 0 -5.7"
              rotation="0 -90 0"
              scale="1.2 1.2 1.2"
            >
            </a-entity>
          </a-entity>

          <!-- depuy_booth -->
          <a-entity link="href: ./exh-depuy">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#depuy_booth"
              position="-9 0 4"
            >
            </a-entity>
          </a-entity>
          <!-- amico_booth -->
          <a-entity link="href: ./exh-amico">
            <a-entity
              *ngIf="isLoaded"
              gltf-model="#amico_booth"
              position="3.55 0 7"
            >
            </a-entity>
          </a-entity>
        </a-entity>
        <!--- end hall -->
        <!-- <a-plane
          color="#000"
          position="0 0 -34.5"
          scale="60 50 5"
          rotation="0 0 0"
          static-body
        ></a-plane> -->
        <!---Floor --->
        <!-- <a-plane
          color="red"
          position="1 0 -10"
          scale="60 50 5"
          rotation="90 0 0"
          static-body
        ></a-plane> -->

        <!-- <a-sphere
          id="homeThumbnail"
          position=" 0 5 -6"
          color="#458554"
          dynamic-body
          grabbable
        ></a-sphere>
        <a-sphere
          id="homeThumbnail"
          position=" 0 6 -6"
          color="#458554"
          dynamic-body
          grabbable
        ></a-sphere> -->

        <!-- Mouse -->
        <!-- <a-entity position="0 1.6 0">
          <a-cursor> </a-cursor>
        </a-entity> -->
        <!-- <a-sky color="##d6d0ef" background=""></a-sky> -->
        <!--  <a-plane position=""></a-plane> -->

        <!-- <a-box id="thumbHome" position="0 1 -4 "></a-box> -->

        <!-- Start Camera Rig -->
        <!-- <a-entity
          position="0 0 21"
          raycaster="objects:.landscape,.environmentGround,.environmentDressing; far:0.5;"
        >
          <a-entity
            position="0 1.6 0"
            camera
            look-controls="pointerLockEnabled:false"
            wasd-controls="acceleration:100"
          >
            <a-cursor> </a-cursor>
          </a-entity>
        </a-entity> -->
        <a-entity position="0 2.2 18">
          <a-entity camera look-controls wasd-controls>
            <a-entity
              position="0 0 -4"
              geometry="primitive: ring; radiusInner: 0.03; radiusOuter: 0.05;"
              material="color: cyan; shader: flat"
              cursor
            >
              <!-- <a-animation
                begin="click"
                easing="ease-in"
                attribute="scale"
                fill="forwards"
                from="0.2 0.2 0.2"
                to="1 1 1"
                dur="150"
              ></a-animation>
              <a-animation
                begin="fusing"
                easing="ease-in"
                attribute="scale"
                fill="backwards"
                from="1 1 1"
                to="0.2 0.2 0.2"
                dur="1500"
              ></a-animation> -->
            </a-entity>
          </a-entity>
        </a-entity>
        <!-- End  Camera Rig -->
      </a-scene>

      <!-- End Scene -->
      <div
        style="
          /* background-color: rgb(195 205 215 / 60%); */
          position: fixed;
          left: 10px;
          top: 10px;
          color: #000;
          padding: 10px;
        "
      >
        <a [routerLink]="['']"
          ><img src="/assets/event/exh/3d/back.svg" style="width: 26px"
        /></a>
        &nbsp;&nbsp;&nbsp;
        <a [routerLink]="['', 'audi']" style="color: #fff"
          ><img src="/assets/event/live1.gif" style="width: 80px"
        /></a>
      </div>
      <div style="position: fixed; left: 20px; bottom: 20px">
        <img src="/assets/event/exh/3d/nav.png" class="nav" />
      </div>
    </div>
  </div>
  <!-- container end-->
</section>
<!-- ts schedule end-->
