<section>
  <div class="container-fluid">
    <div class="row">
      <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
      <div class="col text-center">
        <div style="position: absolute">
          <a (click)="backClicked()" style="color: #fff"
            ><img
              src="/assets/event/exh/3d/white.svg"
              style="width: 26px; margin: 15px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a [routerLink]="['', 'audi']" style="color: #fff"
            ><img src="/assets/event/live1.gif" style="width: 80px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="mailto:abelal1@ITS.JNJ.com"
            style="color: #fff"
            target="_blank"
            ><img
              src="/assets/event/email.png"
              style="width: 60px; padding: 10px"
            />
          </a>

          <!-- <a
              class="btn btn-success float-left"
              style="color: #fff; background-color: #28a745"
              href="https://api.whatsapp.com/send?phone=00971529115152"
              target="_blank"
              >Whatsapp
            </a> -->
        </div>
        <div class="d_1241">
          <img
            src="assets/event/exh/depuy_booth.png"
            alt="Workplace"
            usemap="#workmap"
            width="1241px"
            height="770px"
          />
          <map name="workmap">
            <area
              shape="rect"
              coords="283,367,417,440"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-depuy']"
              (click)="openDialog(linkVedio, 'vedio')"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="780,352,860,454"
              alt=""
              href="assets/event/exh/{{ link1 }}"
              target="_blank"
            />
            <area
              shape="rect"
              coords="883,352,957,454"
              alt=""
              href="assets/event/exh/{{ link2 }}"
              target="_blank"
            />
            <area
              shape="rect"
              coords="980,352,1037,448"
              alt=""
              href="assets/event/exh/{{ link3 }}"
              target="_blank"
            />

            <!-- <area shape="rect" coords="323,265,453,338" alt="vedio" /> -->
          </map>
        </div>
        <div class="d_768">
          <img
            src="assets/event/exh/depuy_booth_768.png"
            alt="Workplace"
            usemap="#workmap_768"
            width="768px"
            height="477px"
          />

          <map name="workmap_768">
            <area
              shape="rect"
              coords="174,226,258,272"
              alt=""
              href="#"
              [routerLink]="['', 'exh-depuy']"
              (click)="openDialog(linkVedio, 'vedio')"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="483,218,533,280"
              alt=""
              href="assets/event/exh/{{ link1 }}"
              target="_blank"
            />
            <area
              shape="rect"
              coords="547,218,593,280"
              alt=""
              href="assets/event/exh/{{ link2 }}"
              target="_blank"
            />
            <area
              shape="rect"
              coords="607,218,706,278"
              alt=""
              href="assets/event/exh/{{ link3 }}"
              target="_blank"
            />
          </map>
        </div>
        <div class="d_320">
          <img
            src="assets/event/exh/depuy_booth_320.png"
            alt="Workplace"
            usemap="#workmap_320"
            width="320px"
            height="199px"
          />

          <map name="workmap_320">
            <area
              shape="rect"
              coords="72,94,107,114"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-depuy']"
              (click)="openDialog(linkVedio, 'vedio')"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="201,90,221,117"
              alt=""
              href="assets/event/exh/{{ link1 }}"
              target="_blank"
            />
            <area
              shape="rect"
              coords="228,90,247,115"
              alt=""
              href="assets/event/exh/{{ link2 }}"
              target="_blank"
            />
            <area
              shape="rect"
              coords="252,90,267,115"
              alt=""
              href="assets/event/exh/{{ link3 }}"
              target="_blank"
            />
          </map>
        </div>
      </div>
    </div>

    <!-- Container end -->
  </div>
</section>
