<section>
  <div class="container-fluid">
    <div class="row">
      <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
      <div class="col p-0 text-center">
        <div class="booth_links">
          <a (click)="backClicked()" style="color: #fff"
            ><img
              src="/assets/event/white.svg"
              style="width: 26px; margin: 15px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a [routerLink]="['', 'halls']" style="color: #fff"
            ><img src="/assets/event/live1.gif" style="width: 80px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a
            *ngIf="whatsapp"
            href="https://api.whatsapp.com/send?phone={{ whatsapp }}"
            target="_blank"
          >
            <img
              src="/assets/event/whatsapp.png"
              style="width: 60px; padding: 7px"
            />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a *ngIf="zoom" href="{{ zoom }}" target="_blank"
            ><img
              src="/assets/event/videocall.png"
              style="width: 60px; padding: 10px"
            />
          </a>
          &nbsp;&nbsp;&nbsp;
          <a *ngIf="zoom_title" target="_blank" style="font-weight: 800"
            >{{ zoom_title }}
          </a>
        </div>
        <div class="d_1241">
          <img
            src="{{ imageLink }}/exhibition/{{ lg_img }}"
            alt=""
            usemap="#workmap"
            width=" {{ lg_width }}"
            height="{{ lg_height }}"
          />

          <map name="workmap">
            <ng-container *ngFor="let link of lg_links">
              <area
                *ngIf="link.type == 'ImagePopup'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(imageLink + '/attachments/' + link.file)"
              />

              <area
                *ngIf="
                  link.type == 'ImageNewPage' ||
                  link.type == 'Pdf' ||
                  link.type == 'Pdf-Link'
                "
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="{{ imageLink }}/attachments/{{ link.file }}"
                target="_blank"
              />
              <area
                *ngIf="link.type == 'Vimeo'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(link.link, 'Vimeo')"
              />
              <area
                *ngIf="link.type == 'Youtube'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(link.link, 'Youtube')"
              />
            </ng-container>
          </map>
        </div>
        <div class="d_768">
          <img
            src="{{ imageLink }}/exhibition/{{ md_img }}"
            alt=""
            usemap="#workmap_768"
            width=" {{ md_width }}"
            height="{{ md_height }}"
          />

          <map name="workmap_768">
            <ng-container *ngFor="let link of md_links">
              <area
                *ngIf="link.type == 'ImagePopup'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(imageLink + '/attachments/' + link.file)"
              />

              <area
                *ngIf="
                  link.type == 'ImageNewPage' ||
                  link.type == 'Pdf' ||
                  link.type == 'Pdf-Link'
                "
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="{{ imageLink }}/attachments/{{ link.file }}"
                target="_blank"
              />
              <area
                *ngIf="link.type == 'Vimeo'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(link.link, 'Vimeo')"
              />
              <area
                *ngIf="link.type == 'Youtube'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(link.link, 'Youtube')"
              />
            </ng-container>
          </map>
        </div>
        <div class="d_320">
          <img
            src="{{ imageLink }}/exhibition/{{ sm_img }}"
            alt=""
            usemap="#workmap_320"
            width=" {{ sm_width }}"
            height="{{ sm_height }}"
          />

          <map name="workmap_320">
            <ng-container *ngFor="let link of sm_links">
              <area
                *ngIf="link.type == 'ImagePopup'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(imageLink + '/attachments/' + link.file)"
              />

              <area
                *ngIf="
                  link.type == 'ImageNewPage' ||
                  link.type == 'Pdf' ||
                  link.type == 'Pdf-Link'
                "
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="{{ imageLink }}/attachments/{{ link.file }}"
                target="_blank"
              />
              <area
                *ngIf="link.type == 'Vimeo'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(link.link, 'Vimeo')"
              />
              <area
                *ngIf="link.type == 'Youtube'"
                shape="rect"
                coords="{{ link.point1 }},{{ link.point2 }}"
                alt=""
                href="#"
                [routerLink]="['', 'booth', slug]"
                (click)="openDialog(link.link, 'Youtube')"
              />
            </ng-container>
          </map>
        </div>
      </div>
    </div>

    <!-- Container end -->
  </div>
</section>
