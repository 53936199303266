<!-- counter start-->

<section class="hero-area centerd-item">
  <div
    class="banner-item"
    style="background-image: url(./assets/images/hero_area/banner2.jpg);"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto">
          <div class="banner-content-wrap text-center">
            <p class="banner-info">
              5 to 7 June 2019, Waterfront Hotel, London
            </p>
            <h1 class="banner-title">digital thinkers Meet up</h1>

            <div class="countdown">
              <div class="counter-item">
                <i class="icon icon-ring-1Asset-1"></i>
                <span class="days">00</span>
                <div class="smalltext">Days</div>
              </div>
              <div class="counter-item">
                <i class="icon icon-ring-4Asset-3"></i>
                <span class="hours">00</span>
                <div class="smalltext">Hours</div>
              </div>
              <div class="counter-item">
                <i class="icon icon-ring-3Asset-2"></i>
                <span class="minutes">00</span>
                <div class="smalltext">Minutes</div>
              </div>
              <div class="counter-item">
                <i class="icon icon-ring-4Asset-3"></i>
                <span class="seconds">00</span>
                <div class="smalltext">Seconds</div>
              </div>
            </div>
            <!-- Countdown end -->
            <div class="banner-btn">
              <a href="#" class="btn">Buy Ticket</a>
            </div>
          </div>
          <!-- Banner content wrap end -->
        </div>
        <!-- col end-->
      </div>
      <!-- row end-->
    </div>
    <!-- Container end -->
  </div>
</section>
<!-- counter end-->
<!-- ts intro start -->

<section class="ts-intro-item section-bg">
  <div class="container">
    <div class="row">
      <div
        class="col-lg-4 wow fadeInUp"
        data-wow-duration="1.5s"
        data-wow-delay="300ms"
      >
        <div class="intro-left-content">
          <h2 class="column-title">
            <span>Why Join Exhibit</span>
            Why you should Join Event
          </h2>
          <p>
            How you transform your business as tech, consumer, habits industry
            dynamic change, Find out from those leading the charge.
          </p>
          <a href="#" class="btn">Buy Ticket</a>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-8">
        <div class="row">
          <div
            class="col-lg-6 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="400ms"
          >
            <div class="single-intro-text mb-30">
              <i class="icon icon-speaker"></i>
              <h3 class="ts-title">Great Speakers</h3>
              <p>
                How you transform your business as technology, consumer, habits
                industry dynamic
              </p>
              <span class="count-number">01</span>
            </div>
            <!-- single intro text end-->
          </div>
          <!-- col end-->
          <div
            class="col-lg-6 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="500ms"
          >
            <div class="single-intro-text mb-30">
              <i class="icon icon-netwrorking"></i>
              <h3 class="ts-title">New People</h3>
              <p>
                How you transform your business as technology, consumer, habits
                industry dynamic
              </p>
              <span class="count-number">02</span>
            </div>
            <!-- single intro text end-->
          </div>
          <!-- col end-->
          <div
            class="col-lg-6 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="600ms"
          >
            <div class="single-intro-text mb-30">
              <i class="icon icon-people"></i>
              <h3 class="ts-title">Networking</h3>
              <p>
                How you transform your business as technology, consumer, habits
                industry dynamic
              </p>
              <span class="count-number">03</span>
            </div>
            <!-- single intro text end-->
          </div>
          <!-- col end-->
          <div
            class="col-lg-6 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="700ms"
          >
            <div class="single-intro-text mb-30">
              <i class="icon icon-fun"></i>
              <h3 class="ts-title">Have Fun</h3>
              <p>
                How you transform your business as technology, consumer, habits
                industry dynamic
              </p>
              <span class="count-number">04</span>
            </div>
            <!-- single intro text end-->
          </div>
          <!-- col end-->
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>
<!-- ts intro end-->

<!-- ts funfact start-->
<section
  class="ts-funfact"
  style="background-image: url(./assets/images/funfact_bg.jpg);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="43">43</span>+
          </h3>
          <h4 class="funfact-title">Our Visionary Speakers</h4>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="62">62</span>
          </h3>
          <h4 class="funfact-title">International Sponsors</h4>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="28">28</span>+
          </h3>
          <h4 class="funfact-title">Workshops We offer</h4>
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-single-funfact">
          <h3 class="funfact-num">
            <span class="counterUp" data-counter="950">950</span>+
          </h3>
          <h4 class="funfact-title">Event Participants</h4>
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>
<!-- ts funfact end-->

<!-- ts speaker start-->
<section
  id="ts-speakers"
  class="ts-speakers speaker-classic"
  style="background-image: url(./assets/images/speakers/speaker_bg.png);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title text-center">
          <span>Listen to the</span>
          Event Speakers
        </h2>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker1.jpg"
              alt=""
            />
            <a
              href="#popup_1"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Fredric Martinsson</a></h3>
            <p>
              Founder, Edilta
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_1" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker1.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker2.jpg"
              alt=""
            />
            <a
              href="#popup_2"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Melisa Lundryn</a></h3>
            <p>
              Lead Designer, Payol
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_2" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker2.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker3.jpg"
              alt=""
            />
            <a
              href="#popup_3"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Agaton Johnsson</a></h3>
            <p>
              Developer Expert
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_3" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker3.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker4.jpg"
              alt=""
            />
            <a
              href="#popup_4"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Rebecca Henrikon</a></h3>
            <p>
              Founder, Cards
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_4" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker4.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker5.jpg"
              alt=""
            />
            <a
              href="#popup_5"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Fredric Martinsson</a></h3>
            <p>
              Founder, Edilta
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_5" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker5.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker6.jpg"
              alt=""
            />
            <a
              href="#popup_6"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Lundryn Melisa </a></h3>
            <p>
              Lead Designer, Payol
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_6" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker1.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->
      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker7.jpg"
              alt=""
            />
            <a
              href="#popup_7"
              class="view-speaker ts-image-popup"
              data-effect="mfp-zoom-in"
            >
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Johnsson Agaton </a></h3>
            <p>
              Developer Expert
            </p>
          </div>
        </div>
        <!-- popup start-->
        <div id="popup_7" class="container ts-speaker-popup mfp-hide">
          <div class="row">
            <div class="col-lg-6">
              <div class="ts-speaker-popup-img">
                <img src="assets/images/speakers/speaker7.jpg" alt="" />
              </div>
            </div>
            <!-- col end-->
            <div class="col-lg-6">
              <div class="ts-speaker-popup-content">
                <h3 class="ts-title">David Robert</h3>
                <span class="speakder-designation">Cheif Architecture</span>
                <img
                  class="company-logo"
                  src="assets/images/sponsors/sponsor-6.png"
                  alt=""
                />
                <p>
                  World is committed to making participation in the event a
                  harass ment free experience for everyone, regardless of level
                  experience gender, gender identity and expression
                </p>
                <h4 class="session-name">
                  Sessions by David
                </h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="speaker-session-info">
                      <h4>Day 1</h4>
                      <span>
                        10.30 - 11.30 am
                      </span>
                      <p>
                        Marketing Matters
                      </p>
                    </div>
                  </div>
                </div>
                <div class="ts-speakers-social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
              <!-- ts-speaker-popup-content end-->
            </div>
            <!-- col end-->
          </div>
          <!-- row end-->
        </div>
        <!-- popup end-->
      </div>
      <!-- col end-->

      <div class="col-lg-3 col-md-6">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/images/speakers/speaker8.jpg"
              alt=""
            />
            <a href="#" class="view-speaker">
              <i class="icon icon-plus"></i>
            </a>
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title"><a href="#">Henrikon Rebecca </a></h3>
            <p>
              Founder, Cards
            </p>
          </div>
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->

  <!-- shap img-->
  <div class="speaker-shap">
    <img
      class="shap1"
      src="assets/images/shap/home_speaker_memphis1.png"
      alt=""
    />
    <img
      class="shap2"
      src="assets/images/shap/home_speaker_memphis2.png"
      alt=""
    />
    <img
      class="shap3"
      src="assets/images/shap/home_speaker_memphis3.png"
      alt=""
    />
  </div>
  <!-- shap img end-->
</section>
<!-- ts speaker end-->

<!-- ts experience start-->
<section id="ts-experiences" class="ts-experiences">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 no-padding">
        <div
          class="exp-img"
          style="background-image: url(./assets/images/cta_img.jpg);"
        ></div>
      </div>
      <!-- col end-->
      <div class="col-lg-6 no-padding align-self-center">
        <div class="ts-exp-wrap">
          <div class="ts-exp-content">
            <h2 class="column-title">
              <span>Get Experience</span>
              Shift your perspective on digital business
            </h2>
            <p>
              How you transform your business as technology, consumer, habits
              industry dynamic s change? Find out from those leading the charge.
            </p>
          </div>
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container fluid end-->
</section>
<!-- ts experience end-->

<!-- ts schedule start-->
<section class="ts-schedule">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title">
          <span>Schedule Details</span>
          Event Schedules
        </h2>
        <div class="ts-schedule-nav">
          <ul class="nav nav-tabs justify-content-center" role="tablist">
            <li class="nav-item">
              <a
                class="active"
                title="Click Me"
                href="#date1"
                role="tab"
                data-toggle="tab"
              >
                <h3>5th June</h3>
                <span>Friday</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class=""
                href="#date2"
                title="Click Me"
                role="tab"
                data-toggle="tab"
              >
                <h3>6th June</h3>
                <span>Saturday</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class=""
                href="#date3"
                title="Click Me"
                role="tab"
                data-toggle="tab"
              >
                <h3>7th June</h3>
                <span>Sunday</span>
              </a>
            </li>
          </ul>
          <!-- Tab panes -->
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-12">
        <div class="tab-content schedule-tabs schedule-tabs-item">
          <div role="tabpanel" class="tab-pane active" id="date1">
            <div class="row">
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-left">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/eakers/speaker1.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">10.30 - 11.30 AM</span>
                  <h3 class="schedule-slot-title">Marketing Matters</h3>
                  <h4 class="schedule-slot-name">@ Henrikon Rebecca</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-right">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/eakers/speaker2.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">11.30 - 12.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Reinventing Experiences
                  </h3>
                  <h4 class="schedule-slot-name">@ Johnsson Agaton</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-left">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/eakers/speaker3.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">12.30 - 01.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Cultures of Creativity
                  </h3>
                  <h4 class="schedule-slot-name">@ Lundryn Melisa</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-right">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/eakers/speaker4.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">01.30 - 02.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Human Centered Design
                  </h3>
                  <h4 class="schedule-slot-name">@ Fredric Martinsson</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
            </div>
            <!-- row end-->
            <div class="schedule-listing-btn">
              <a href="#" class="btn">More Details</a>
            </div>
          </div>
          <!-- tab pane end-->

          <div role="tabpanel" class="tab-pane" id="date2">
            <div class="row">
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-left">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/eakers/speaker5.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">02.30 - 03.30 PM</span>
                  <h3 class="schedule-slot-title">Marketing Matters</h3>
                  <h4 class="schedule-slot-name">@ Rebecca Henrikon</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-right">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker6.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">03.30 - 04.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Reinventing Experiences
                  </h3>
                  <h4 class="schedule-slot-name">@ Fredric Martinsson</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-left">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker7.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">04.30 - 05.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Cultures of Creativity
                  </h3>
                  <h4 class="schedule-slot-name">@ Melisa Lundryn</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-right">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker8.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">05.30 - 06.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Human Centered Design
                  </h3>
                  <h4 class="schedule-slot-name">@ Agaton Johnsson</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
            </div>
            <!-- row end-->
            <div class="schedule-listing-btn">
              <a href="#" class="btn">More Details</a>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" id="date3">
            <div class="row">
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-left">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker1.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">10.30 - 11.30 AM</span>
                  <h3 class="schedule-slot-title">Marketing Matters</h3>
                  <h4 class="schedule-slot-name">@ Rebecca Henrikon</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-right">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker2.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">11.30 - 12.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Reinventing Experiences
                  </h3>
                  <h4 class="schedule-slot-name">@ Hall Building</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-left">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker3.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">01.30 - 02.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Cultures of Creativity
                  </h3>
                  <h4 class="schedule-slot-name">@ Fredric Martinsson</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
              <div class="col-lg-6">
                <div class="schedule-listing-item schedule-right">
                  <img
                    class="schedule-slot-speakers"
                    src="assets/images/speakers/speaker4.jpg"
                    alt=""
                  />
                  <span class="schedule-slot-time">03.30 - 04.30 PM</span>
                  <h3 class="schedule-slot-title">
                    Human Centered Design
                  </h3>
                  <h4 class="schedule-slot-name">@ Henrikon Rebecca</h4>
                  <p>
                    How you transform your business technolog consumer habits
                    industry dynamics change Find out from those leading the
                    charge How you
                  </p>
                </div>
              </div>
              <!-- col end-->
            </div>
            <!-- row end-->
            <div class="schedule-listing-btn">
              <a href="#" class="btn">More Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- container end-->
</section>
<!-- ts schedule end-->

<!-- ts pricing start-->
<section
  class="ts-pricing gradient"
  style="background-image: url(./assets/images/pricing/pricing_img.jpg);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title white">
          <span>Pricing Plans</span>
          Get your Ticket
        </h2>
      </div>
      <!-- section title end-->
    </div>
    <!-- col end-->
    <!-- row end-->
    <div class="row">
      <div class="col-lg-4">
        <div class="pricing-item disebled">
          <img class="pricing-dot" src="images/pricing/dot.png" alt="" />
          <div class="ts-pricing-box">
            <div class="ts-pricing-header">
              <h2 class="ts-pricing-name">Early Bird</h2>
              <h3 class="ts-pricing-price">
                <span class="currency">$</span>219
              </h3>
            </div>
            <div class="ts-pricing-progress">
              <p class="amount-progres-text">
                Available tickets for this price
              </p>
              <div class="ts-progress">
                <div class="ts-progress-inner" style="width: 100%;"></div>
              </div>
              <p class="ts-pricing-value">350/500</p>
            </div>
            <div class="promotional-code">
              <p class="promo-code-text">Enter Promotional Code</p>
              <a href="#" class="btn pricing-btn">Sold Out</a>
              <p class="vate-text">All prices exclude 25% VAT</p>
            </div>
          </div>
          <!-- ts pricing box-->
          <img class="pricing-dot1" src="images/pricing/dot.png" alt="" />
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-4">
        <div class="pricing-item">
          <img class="pricing-dot" src="images/pricing/dot.png" alt="" />
          <div class="ts-pricing-box">
            <span class="big-dot"></span>
            <div class="ts-pricing-header">
              <h2 class="ts-pricing-name">Regular</h2>
              <h3 class="ts-pricing-price">
                <span class="currency">$</span>399
              </h3>
            </div>
            <div class="ts-pricing-progress">
              <p class="amount-progres-text">
                Available tickets for this price
              </p>
              <div class="ts-progress">
                <div class="ts-progress-inner" style="width: 75%;"></div>
              </div>
              <p class="ts-pricing-value">350/500</p>
            </div>
            <div class="promotional-code">
              <p class="promo-code-text">Enter Promotional Code</p>
              <a href="#" class="btn pricing-btn">Buy Ticket</a>
              <p class="vate-text">All prices exclude 25% VAT</p>
            </div>
          </div>
          <!-- ts pricing box-->
          <img class="pricing-dot1" src="images/pricing/dot.png" alt="" />
        </div>
      </div>
      <!-- col end-->
      <div class="col-lg-4">
        <div class="pricing-item">
          <img class="pricing-dot" src="images/pricing/dot.png" alt="" />
          <div class="ts-pricing-box">
            <span class="big-dot"></span>
            <div class="ts-pricing-header">
              <h2 class="ts-pricing-name">Platinum</h2>
              <h3 class="ts-pricing-price">
                <span class="currency">$</span>699
              </h3>
            </div>
            <div class="ts-pricing-progress">
              <p class="amount-progres-text">
                Available tickets for this price
              </p>
              <div class="ts-progress">
                <div class="ts-progress-inner" style="width: 50%;"></div>
              </div>
              <p class="ts-pricing-value">250/500</p>
            </div>
            <div class="promotional-code">
              <p class="promo-code-text">Enter Promotional Code</p>
              <a href="#" class="btn pricing-btn">Buy Ticket</a>
              <p class="vate-text">All prices exclude 25% VAT</p>
            </div>
          </div>
          <!-- ts pricing box-->
          <img class="pricing-dot1" src="images/pricing/dot.png" alt="" />
        </div>
      </div>
      <!-- col end-->
    </div>
  </div>
  <!-- container end-->
  <div class="speaker-shap">
    <img class="shap2" src="images/shap/pricing_memphis1.png" alt="" />
  </div>
</section>
<!-- ts pricing end-->

<!-- ts sponsors start-->
<section
  class="ts-intro-sponsors"
  style="background-image: url(./assets/images/sponsors/sponsor_img.jpg);"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title white">
          <span>Who helps us</span>
          Our Sponsors
        </h2>
        <!-- section title end-->
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-12">
        <div class="sponsors-logo text-center">
          <a href=""><img src="images/sponsors/sponsor1.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor2.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor3.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor4.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor5.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor6.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor7.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor8.png" alt="" /></a>
          <a href=""><img src="images/sponsors/sponsor9.png" alt="" /></a>
          <div class="sponsor-btn text-center">
            <a href="#" class="btn">Become a Sponsor</a>
          </div>
        </div>
        <!-- sponsors logo end-->
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>
<!-- ts sponsors end-->

<!-- ts map direction start-->
<section
  class="ts-map-direction wow fadeInUp"
  data-wow-duration="1.5s"
  data-wow-delay="400ms"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-5">
        <h2 class="column-title">
          <span>Reach us</span>
          Get Direction to the Event Hall
        </h2>

        <div class="ts-map-tabs">
          <ul class="nav" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#profile"
                role="tab"
                data-toggle="tab"
                >Venue</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#buzz" role="tab" data-toggle="tab"
                >Time</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="#references"
                role="tab"
                data-toggle="tab"
                >How to get there</a
              >
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content direction-tabs">
            <div role="tabpanel" class="tab-pane active" id="profile">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
            <!-- tab pane end-->
            <div role="tabpanel" class="tab-pane fade" id="buzz">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
            <div role="tabpanel" class="tab-pane fade" id="references">
              <div class="direction-tabs-content">
                <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                <p class="derecttion-vanue">
                  1Hd- 50, 010 Avenue, NY 90001<br />
                  United States
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Tickets info</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="contact-info-box">
                      <h3>Programme Details</h3>
                      <p><strong>Name:</strong> Ronaldo König</p>
                      <p><strong>Phone:</strong> 009-215-5595</p>
                      <p><strong>Email: </strong> info@example.com</p>
                    </div>
                  </div>
                </div>
                <!-- row end-->
              </div>
              <!-- direction tabs end-->
            </div>
          </div>
        </div>
        <!-- map tabs end-->
      </div>
      <!-- col end-->
      <div class="col-lg-6 offset-lg-1">
        <div class="ts-map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9028133968723!2d-73.99208268505396!3d40.74216397932861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a4119ce269%3A0x9dec0c979b575972!2sEataly+NYC+Flatiron!5e0!3m2!1sen!2sbd!4v1541577288827"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- col end-->
  </div>
  <!-- container end-->
  <div class="speaker-shap">
    <img class="shap1" src="images/shap/Direction_memphis3.png" alt="" />
    <img class="shap2" src="images/shap/Direction_memphis2.png" alt="" />
    <img class="shap3" src="images/shap/Direction_memphis4.png" alt="" />
    <img class="shap4" src="images/shap/Direction_memphis1.png" alt="" />
  </div>
</section>
<!-- ts map direction end-->
