<div class="footer-area1">
  <!-- newslatter start-->
  <!-- <app-newslatter></app-newslatter> -->
  <!-- newslatter  end-->

  <!-- footer start-->
  <footer class="ts-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 mx-auto">
          <!-- <div class="text-center">
            <img
              src="assets/event/ancc.jpg"
              style="width: 130px;"
              class=""
              alt=""
            />
          </div> -->

          <div class="ts-footer-social text-center mb-30">
            <ul>
              <!-- <li>
                <a href="https://www.facebook.com/EliteConferences/"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li> -->
              <!-- <li>
                <a href="https://twitter.com/EliteConferences"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-google-plus"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/EliteConferences/"><i class="fa fa-instagram"></i></a>
              </li> -->
            </ul>
          </div>
          <!-- footer social end-->

          <!-- footer menu end-->
          <div class="copyright-text text-center">
            <p>
              Developed by
              <a href="https://primesolutionsweb.com/">Prime Solutions.</a> All
              Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- footer end-->
  <!-- <div class="BackTo">
    <a href="#" class="fa fa-angle-up" aria-hidden="true"></a>
  </div> -->
</div>
