<!-- ts schedule start-->
<section class="ts-schedule">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 mx-auto text-center">
        <h2 class="section-title">
          <span> The conference timezone is set to (GMT+2)</span>
          Event Schedules
        </h2>
        <a
          style="cursor: pointer"
          class="p-2"
          href="assets/event/ESSHM-2021-Program.pdf"
          download
          target="_blank"
        >
          Download PDF
        </a>
        <br /><br />
        <div class="ts-schedule-nav">
          <ul class="nav nav-tabs justify-content-center" role="tablist">
            <li class="nav-item" *ngFor="let agen of agenda; let first = first">
              <a
                [class.active]="first"
                title="Click Me"
                href="#date{{ agen.id }}"
                role="tab"
                data-toggle="tab"
              >
                <h3>
                  {{ agen.title }}
                </h3>
                <span>
                  {{ agen.start_date | date: "EEEE" }}
                  {{ agen.start_date | date: "d MMMM" }}
                </span>

                <!-- <h3>
                  {{ agen.start_date | date: "d MMMM" }}
                </h3>
                <span> {{ agen.start_date | date: "EEEE" }} </span> -->
              </a>
            </li>
          </ul>
          <!-- Tab panes -->
        </div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
    <div class="row">
      <div class="col-lg-12">
        <div class="tab-content schedule-tabs">
          <ng-container *ngFor="let agen of agenda; let first = first">
            <div
              role="tabpanel"
              class="tab-pane"
              [class.active]="first"
              id="date{{ agen.id }}"
            >
              <ng-container *ngFor="let act of agen.activities">
                <div class="schedule-listing" *ngIf="act.type == 'Lecture'">
                  <div class="schedule-slot-time">
                    <span>
                      {{ act.start_time_str }} -
                      {{ act.end_time_str }}
                    </span>
                    <!-- <span>
                      {{ act.start_time | toDateObj | date: "hh:mm" }} -
                      {{ act.end_time | toDateObj | date: "hh:mm aaa" }}
                    </span> -->
                    Lecture
                  </div>
                  <div class="schedule-slot-info">
                    <!-- <a href="#">
                      <img
                        class="schedule-slot-speakers"
                        src="assets/images/speakers/speaker1.jpg"
                        alt=""
                      />
                    </a> -->
                    <div class="schedule-slot-info-content">
                      <h3 class="schedule-slot-title">
                        {{ act.title }}
                        <strong *ngIf="act.location"
                          >@ {{ act.location }}</strong
                        >
                      </h3>
                      <!-- <p>
                        How you transform your business as technology, consumer,
                        habits industry dynamics change? Find out from those
                        leading the charge. How you transform
                      </p> -->
                    </div>
                    <!--Info content end -->
                  </div>
                  <!-- Slot info end -->
                </div>
                <!--schedule-listing end -->

                <div
                  class="schedule-listing launce"
                  *ngIf="act.type == 'String' || act.type == 'Session'"
                >
                  <div class="schedule-slot-time">
                    <span>
                      {{ act.start_time_str }} -
                      {{ act.end_time_str }}
                    </span>
                    Session
                    <!-- <span>
                      {{ act.start_time | toDateObj | date: "hh:mm" }} -
                      {{ act.end_time | toDateObj | date: "hh:mm aaa" }}
                    </span> -->
                  </div>
                  <div class="schedule-slot-info">
                    <div class="schedule-slot-info-content">
                      <h3 class="schedule-slot-title">
                        {{ act.title }}
                        <strong *ngIf="act.location"
                          >@ {{ act.location }}</strong
                        >
                      </h3>
                      <!-- <a href="#">
                        <img src="assets/images/schedule_lunch.png" alt="" />
                      </a> -->
                    </div>
                    <!--Info content end -->
                  </div>
                  <!-- Slot info end -->
                </div>
                <!--schedule-listing end -->
              </ng-container>

              <!-- <div class="schedule-listing-btn">
              <a href="#" class="btn">More Details</a>
            </div> -->
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- container end-->
</section>
<!-- ts schedule end-->
