import { AuthenticationService } from 'app/shared/services/authentication.service';

import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'app/core/http/api.service';
import { environment as env } from '@env/environment';

import { Router } from '@angular/router';

import { ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  agenda = 'agenda';
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  scrollTo(className: string): void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
  logout() {
    console.log('the first comman');
    this.auth.logout();

    // this.userLogin = false;

    this.router.navigateByUrl('login');
    this.authLogoutYes();
  }
  authLogoutYes() {
    this.auth.changeMessage('');
  }
  // goTo(location: string): void {
  //   window.location.hash = '';
  //   window.location.hash = location;
  // }

  openDialog(d_name: string, d_type = 'image') {
    const dialogRef = this.dialog.open(DialogAddQuestionSidebar, {
      data: { name: d_name, type: d_type },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

////////////////////////////////////

@Component({
  selector: 'dialog-add-question-sidebar',
  templateUrl: 'dialog-add-question-sidebar.html',
})
export class DialogAddQuestionSidebar {
  name = 'in Dialog';
  type = 'image';
  // link = '';
  hall: string = '';
  textaraVal: string = '';

  userToken: string;
  conference_id: string = env.selectedEvent;
  result: any;
  speakers: any;

  linkVedio = 'https://player.vimeo.com/video/511909826';
  constructor(
    private apiServerice: ApiService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: { name: string; type: string }
  ) {
    this.name = data.name;
    this.type = data.type;

    this.doSpeakers();

    this.userToken = this.cookieService.get('token');

    var tem = this.activatedRoute.snapshot.paramMap.get('hall');
    if (tem == null) this.hall = '';
    else this.hall = tem;
    // this.link = 'https://www.youtube.com/embed/' + this.name;
  }
  doSpeakers() {
    this.apiServerice
      .doGet(`conferences/${env.selectedEvent}/today_agenda_speakers`)
      .subscribe((res) => {
        // console.log('today_agenda_speakers', res);
        this.speakers = res;
      });
  }
  add_ques(form: any) {
    // const ca: ChatMember = { name: this.name, text: this.textaraVal };
    // this.todos.push(ca);
    // if (!this.textaraVal) {
    //   return;
    // }
    form.value.token = this.userToken;

    this.apiServerice
      .doPost(`questions/${env.selectedEvent}`, form.value)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        // console.log('doPost', res);

        this.result = res;
        if (this.result.status == 'success') {
        }
        // this.status = res.status;
        // this.message = res.message;
      });

    this.textaraVal = '';
  }
}
