<!-- Page Banner end -->
<!-- ts intro start -->
<section class="ts-contact" id="contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title text-center">
          <span>Get Information</span>
          Organized by
        </h2>
        <!-- <div class="organizing">
          <img src="assets/event/blue_ocean.png" width="210px" alt="" />
        </div> -->
      </div>
      <!-- col end-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="single-intro-text single-contact-feature">
          <div class="organizing">
            <img src="assets/event/contin.jpg" width="210px" alt="" />
          </div>
          <p><strong>Tel:</strong> +202 376 219 24</p>
          <p><strong>Mob:</strong> +201 227 434 217</p>
          <p><strong>Website:</strong> http://www.continental.com.eg</p>
          <br />
          <!-- For information and registration
          <p><strong>Email:</strong>: asd@gmail.com</p>
          <span class="count-number fa fa-paper-plane"></span> -->
        </div>
        <!-- single intro text end-->
        <div class="border-shap left"></div>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->
  </div>
  <!-- container end-->
</section>
<!-- ts contact end-->
