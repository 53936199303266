import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'app/core/http/api.service';
import { environment as env } from '@env/environment';

import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-booth',
  templateUrl: './booth.component.html',
  styleUrls: ['./booth.component.scss'],
})
export class BoothComponent implements OnInit {
  slug: string = '';

  imageLink = env.imageLink;
  booth: any;
  lg_links: any;
  md_links: any;
  sm_links: any;
  result: any;

  email: string = '';
  zoom: string = '';
  zoom_title: string = '';
  whatsapp: string = '';
  youtube: string = '';
  vimeo: string = '';
  lg_img: string = '';
  lg_height: string = '';
  lg_width: string = '';
  md_img: string = '';
  md_height: string = '';
  md_width: string = '';
  sm_img: string = '';
  sm_height: string = '';
  sm_width: string = '';

  ////
  link1 = 'novartis_Aimovig_A4_Advert_01.png';
  link2 = 'novartis_Aimovig_A4_Advert_02.png';
  link3 = 'novartis_Aimovig_A4_Advert_03.png';
  link4 = '';
  link5 = '';
  linkVedio = 'TuLJLTR00y0';
  ///
  constructor(
    private apiServerice: ApiService,
    private _location: Location,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug') ?? '';

    this.getBooth();
  }

  ngOnInit(): void {}
  getBooth() {
    // console.log('Besm allah ');
    console.log(`exhibition/${env.selectedEvent}/booth/${this.slug}`);
    this.apiServerice
      .doGet(`exhibition/${env.selectedEvent}/booth/${this.slug}`)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        console.log('res', res);
        this.result = res;
        this.booth = this.result.booth;
        // console.log('booth', this.booth);
        this.lg_links = this.result.lg_links;
        this.md_links = this.result.md_links;
        this.sm_links = this.result.sm_links;

        this.email = this.booth.email;
        this.zoom = this.booth.zoom;
        this.zoom_title = this.booth.zoom_title;
        this.whatsapp = this.booth.whatsapp;
        this.youtube = this.booth.youtube;
        this.vimeo = this.booth.vimeo;

        this.lg_img = this.booth.lg_img;
        this.lg_height = this.booth.lg_height;
        this.lg_width = this.booth.lg_width;
        console.log('lg_width', this.lg_width);
        this.md_img = this.booth.md_img;
        this.md_height = this.booth.md_height;
        this.md_width = this.booth.md_width;

        this.sm_img = this.booth.sm_img;
        this.sm_height = this.booth.sm_height;
        this.sm_width = this.booth.sm_width;
      });
  }
  backClicked() {
    this._location.back();
  }

  openDialog(d_name: string, d_type = 'image') {
    const dialogRef = this.dialog.open(DialogBooth, {
      data: { name: d_name, type: d_type },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
@Component({
  selector: 'dialog-booth',
  templateUrl: 'dialog-booth.html',
})
export class DialogBooth {
  name = 'in Dialog';
  type = 'image';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name: string; type: string }
  ) {
    this.name = data.name;
    this.type = data.type;
  }
}
