<mat-dialog-content class="mat-typography">
  <div id="questions" class="questionTab mb-3 text-center">
    Submit a Question
  </div>

  <form #f="ngForm" (ngSubmit)="add_ques(f)" class="hero-form">
    <!-- <select
      class="form-select mb-3"
      name="to_user_id"
      ngModel
      #to_user_id="ngModel"
      required
    >
      <option value="">Choose speaker <span class="red">*</span></option>
      <option *ngFor="let spe of speakers" value="{{ spe.id }}">
        {{ spe.name }}
      </option>
    </select>

    <div
      *ngIf="
        (to_user_id.value == '' && to_user_id.dirty) ||
        (f.submitted && to_user_id.value == '')
      "
      class="alert alert-danger"
    >
      Speaker is required
    </div> -->
    <input type="hidden" name="to_user_id" ngModel="" />
    <div class="align-self-end">
      <textarea
        class="p-0 form-control mb-3"
        name="question"
        ngModel
        rows="2"
        rows="5"
        placeholder=" Enter your question"
      ></textarea>

      <input type="hidden" name="conference_id" ngModel="{{ conference_id }}" />
      <input type="hidden" name="hall" ngModel="{{ hall }}" />
      <input type="hidden" name="token" ngModel="1" />
      <div class="row">
        <div class="col-sm-12 text-center">
          <button
            class="btn btn-secondary btn-sm float-left"
            style="
              line-height: 40px;
              height: 40px;
              padding: 0px 18px;
              margin-top: 5px;
            "
            type="submit"
          >
            Send
          </button>
        </div>
      </div>

      <div>
        <div
          *ngIf="f.submitted && result?.status == 'success'"
          class="alert alert-success"
        >
          {{ result.message }}
        </div>
        <div
          *ngIf="f.submitted && result?.status == 'error'"
          class="alert alert-danger"
        >
          {{ result.message }}
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end" class="p-0">
  <button mat-button mat-dialog-close>Close</button>

</mat-dialog-actions> -->
