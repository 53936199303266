<div class="row p-0 top-bar">
  <div class="col-12">
    <img class="logo" src="./assets/img/logo-campus.png" alt="" />
  </div>
</div>
<div class="row blobs">
  <div class="col-12 p-0">
    <img src="./assets/img/top.png" class="img-fluid" />
  </div>
</div>
