<svg xmlns="http://www.w3.org/2000/svg" style="display: none">
  <symbol id="home" viewBox="0 0 16 16" fill="white">
    <title>home</title>
    <path
      d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"
    />
  </symbol>

  <symbol id="logout" viewBox="0 0 16 16" fill="white">
    <path
      fill-rule="evenodd"
      d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
    />
    <path
      fill-rule="evenodd"
      d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
    />
  </symbol>

  <symbol id="close" viewBox="0 0 16 16" fill="white">
    <title>Close</title>

    <path
      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
    />
  </symbol>

  <symbol id="agenda" viewBox="0 0 16 16" fill="white">
    <path
      d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
    />
    <path
      d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
    />
    <path
      d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
    />
  </symbol>

  <symbol id="attachment" viewBox="0 0 16 16" fill="white">
    <path
      d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"
    />
  </symbol>

  <symbol id="question" viewBox="0 0 16 16" fill="white">
    <path
      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
    />
    <path
      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
    />
  </symbol>
  <symbol id="questionlist" viewBox="0 0 16 16" fill="white">
    <path
      fill-rule="evenodd"
      d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
    />
  </symbol>
</svg>

<div class="side-bar">
  <a
    [routerLink]="['/', 'live', 'a']"
    class="d-block mt-4 mb-5 link-dark text-decoration-none"
    matTooltipPosition="right"
    matTooltipHideDelay="1000"
    matTooltip="Home"
  >
    <svg class="bi" width="40" height="32">
      <use xlink:href="#home"></use>
    </svg>
    <span class="visually-hidden">Home</span>
  </a>

  <!-- <a
    class="d-block pb-3 link-dark text-decoration-none"
    matTooltipPosition="right"
    matTooltipHideDelay="1000"
    matTooltip="Question list"
  >
    <svg class="bi" width="40" height="32">
      <use xlink:href="#questionlist"></use>
    </svg>
    <span class="visually-hidden">question list</span>
  </a> -->

  <a
    class="d-block pb-3 link-dark text-decoration-none"
    title="agenda"
    matTooltipPosition="right"
    matTooltipHideDelay="1000"
    matTooltip="Agenda"
    (click)="scrollTo('agenda')"
  >
    <svg class="bi" width="40" height="32">
      <use xlink:href="#agenda"></use>
    </svg>
    <span class="visually-hidden">agenda</span>
  </a>
  <a
    (click)="openDialog('')"
    class="d-block pb-3 link-dark text-decoration-none"
    at-raised-button
    matTooltipPosition="right"
    matTooltipHideDelay="1000"
    matTooltip="Submit a Question"
  >
    <svg class="bi" width="40" height="32">
      <use xlink:href="#question"></use>
    </svg>
    <span class="visually-hidden">Submit a Question</span>
  </a>
   <!-- 
  <a
    [matMenuTriggerFor]="menu"
    matTooltipPosition="right"
    matTooltipHideDelay="1000"
    matTooltip="Attachments"
    class="d-block pb-3 link-dark text-decoration-none"
  >
    <svg class="bi" width="40" height="32">
      <use xlink:href="#attachment"></use>
    </svg>
    <span class="visually-hidden">Attachments</span>
  </a>

  <mat-menu #menu="matMenu">
    <a
      mat-menu-item
      href="./assets/att/24272_PICO_Karlakki__2016__Evidence_in_focus_AW_0420_pdf_838506.pdf"
      target="_blank"
      download
    >
      PICO Karlakki 2016 Evidence in focus AW
    </a>
    <a
      mat-menu-item
      href="./assets/att/24600_PICO_7_Nherera__2017__Evidence_in_focus_AW_0520_pdf_844982.pdf"
      target="_blank"
      download
    >
      PICO 7 Nherera 2017 Evidence in focus AW 0520
    </a>
    <a
      mat-menu-item
      href="./assets/att/27566_PICO_Ortho_eDetail_aid_1020_AW_pdf_897258.pdf"
      target="_blank"
      download
    >
      PICO Ortho eDetail aid 1020 AW
    </a>
    <a
      mat-menu-item
      href="./assets/att/28571_PICO_Saunders__2020__Evidence_in_focus_0121_AW_pdf_911719.pdf"
      target="_blank"
      download
    >
      PICO Saunders 2020 Evidence in focus
    </a>
    <a mat-menu-item [routerLink]="['/show-vimeo/535753469']">
      PICO Global Incision MOA LQ
    </a>
  </mat-menu>
-->
  <div class="bottom-controlls">
    <a
      [routerLink]="['/', 'live', 'a']"
      (click)="logout()"
      class="d-block pb-3 link-dark text-decoration-none"
      matTooltipPosition="right"
      matTooltipHideDelay="1000"
      matTooltip="Logout"
    >
      <svg class="bi" width="40" height="32">
        <use xlink:href="#logout"></use>
      </svg>
      <span class="visually-hidden">logout</span>
    </a>
  </div>
</div>
