<!-- ts sponsors start-->
<section id="ts-sponsors" class="ts-sponsors">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-12 mx-auto">
        <!-- <h2 class="section-title text-center">
          
            Halls
          </h2> -->
      </div>
    </div>
    <!--- Start row--->
    <div class="sponsors-wrap">
      <div class="row sponsor-padding text-center">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-4 pb-4">
          <!-- <h3 class="sponsor-title text-center py-4"> Sponsor</h3> -->
          <a [routerLink]="['/', 'live', 'a']" class="sponsors-logo1">
            <img
              src="assets/event/hall1.png"
              alt=""
              style="border: 5px solid #000; padding: 20px"
            />
          </a>
        </div>
        <div class="col-sm-12 col-md-4">
          <!-- <h3 class="sponsor-title text-center py-4">Organizer</h3> -->
          <a [routerLink]="['/', 'live', 'b']" class="sponsors-logo1">
            <img
              src="assets/event/hall2.png"
              alt=""
              style="border: 5px solid #000; padding: 20px"
            />
          </a>
        </div>
        <div class="col-md-2"></div>
        <!-- Col 2 end -->
      </div>
    </div>

    <!--/ Content row 1 end -->

    <!-- <div class="row">
          <div class="col-lg-12 mx-auto">
            <div class="general-btn text-center">
              <a class="btn" href="#">Become A Sponsor</a>
            </div>
          </div>
        </div> -->
    <!--/ Content row 3 end -->
  </div>
  <!--/ Container end -->
</section>
<!-- ts ts sponsors end-->
