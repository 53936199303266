<!-- <h2 mat-dialog-title>Install Angular</h2> -->
<mat-dialog-content class="mat-typography">
  <iframe
    *ngIf="type=='vimeo'"
    [src]=" 'https://player.vimeo.com/video/'+name  | safeUrl"
    width="560"
    height="315"
    frameborder="0"
    title="My video"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  ></iframe>

  <iframe
    *ngIf="type=='vedio'"
    width="560"
    height="315"
    [src]="link | safeUrl"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <img
    *ngIf="type=='image'"
    src="assets/event/exh/{{name}}"
    class="img-fluid"
  />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
