<div class="container-fluid p-0 main-wrapper">
  <app-header></app-header>
  <app-side-bar></app-side-bar>
  <div class="container" style="min-height: 700px">
    <div class="row">
      <div class="col-10 offset-2 text-center">
        <div class="ratio ratio-16x9">
          <iframe
            [src]="link | safeUrl"
            width="560"
            height="315"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <!-- Container end -->
  </div>
  <div class="footer">
    <img src="./assets/img/bottom.png" class="blob-bright img-fluid" />
  </div>
</div>
