import { Component, OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ApiService } from 'app/core/http/api.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
})
export class AgendaComponent implements OnInit {
  agenda: any;
  constructor(private apiServerice: ApiService) {}
  pdfUrl =
    'https://blueocean-me.com/cm_ups/agendas/2020/09/22/19033645665f6a6b1cab5f29_29926049.pdf';
  ngOnInit(): void {
    this.doGet();
  }
  doGet() {
    // console.log('Besm allah ');
    // console.log(`conferences/${this.conference.id}`);
    this.apiServerice.doGet(`agenda/${env.selectedEvent}`).subscribe((res) => {
      // console.log('Ya allah');
      // console.log('res', res);
      this.agenda = res;
    });
  }

  // test() {
  // let str = '2020-07-23 19:00:00';

  //   console.log(
  //     ' asasgagsdhagsghghs',
  // formatDate(str.replace(' ', 'T'), 'hh:mm a', 'en-US')
  //   );

  //   console.log('date', Date());

  //   console.log('date44444', new Date());
  //   console.log('getSeconds', new Date(str).getSeconds());
  //   console.log('getSeconds', new Date(str).getSeconds());
  //   console.log('getSeconds', new Date(str).getSeconds());
  //   // console.log('date3', this.formatAMPM(str));

  //   let newDate = new Date(Date.parse(Date()));

  //   console.log('Atef Gad', newDate);
  // }

  // formTime(str: string) {
  //   ////
  //   // let time = new Date(str);
  //   let time = new Date(Date.parse(Date()));
  //   // console.log(time.getHours() + ':' + time.getMinutes());
  //   return time.getHours() + ':' + time.getMinutes();
  // }
  // toDate(str: string) {
  //   // return str;
  //   return new Date(str.replace(' ', 'T'));
  // }

  //   formatAMPM(str: string) {
  // var date =new Date(str);
  //     var hours = date.getHours();
  //     var minutes = date.getMinutes();
  //     var ampm = hours >= 12 ? 'pm' : 'am';
  //     hours = hours % 12;
  //     hours = hours ? hours : 12; // the hour '0' should be '12'
  //     minutes = minutes < 10 ? '0' + minutes : minutes;
  //     var strTime = hours + ':' + minutes + ' ' + ampm;
  //     return strTime;
  //   }

  // nth(d) {
  //   if (d > 3 && d < 21) return 'th';
  //   switch (d % 10) {
  //     case 1:
  //       return 'st';
  //     case 2:
  //       return 'nd';
  //     case 3:
  //       return 'rd';
  //     default:
  //       return 'th';
  //   }
  // }
}
