import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from './core/http/api.service';
import { environment as env } from '@env/environment';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router'; // import Router and NavigationEnd
// declare ga as a function to set and sent the events
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  constructor(
    private apiServerice: ApiService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle(env.con_name);
    // console.log(window.paypal);
    // window.VRDisplayCapabilities.Buttons({
    //   style:{
    //     layout:'horizontal'
    //   }
    // }).render(this.paypalRef.);

    // subscribe to router events and send page views to Google Analytics
  }
  ngAfterViewInit(): void {
    // const interval = setInterval(() => {
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       ga('set', 'page', event.urlAfterRedirects);
    //       ga('send', 'pageview');
    //     }
    //   });
    // }, 250);
  }

  doGet() {
    // console.log('Besm allah');
    this.apiServerice.doGet('conferences').subscribe((res) => {
      console.log('res', res);
    });
  }
  doDelete() {
    this.apiServerice.doDelete().subscribe((res) => {
      console.log('res', res);
    });
  }
  doPost() {
    // this.apiServerice.doPost('asas', '{ass:121}').subscribe((res) => {
    //   console.log('res', res);
    // });
  }
  doPut() {
    this.apiServerice.doPut().subscribe((res) => {
      console.log('res', res);
    });
  }
  title = 'medical-events';
}
