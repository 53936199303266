<section>
  <div class="container-fluid">
    <div class="row">
      <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
      <div class="col text-center">
        <div style="position: absolute">
          <a (click)="backClicked()" style="color: #fff"
            ><img
              src="/assets/event/exh/3d/white.svg"
              style="width: 26px; margin: 15px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a [routerLink]="['', 'audi']" style="color: #fff"
            ><img src="/assets/event/live1.gif" style="width: 80px"
          /></a>
          ⁦ &nbsp;&nbsp;&nbsp;
          <a
            href="https://api.whatsapp.com/send?phone=00971505523895⁩"
            target="_blank"
          >
            <img
              src="/assets/event/whatsapp.png"
              style="width: 60px; padding: 7px"
            />
          </a>
          <!-- <a
              class="btn btn-success float-left"
              style="color: #fff; background-color: #28a745"
              href="https://api.whatsapp.com/send?phone=00971529115152"
              target="_blank"
              >Whatsapp
            </a> -->
        </div>
        <div class="d_1241">
          <img
            src="assets/event/exh/amico_booth.jpg"
            alt="Workplace"
            usemap="#workmap"
            width="1241px"
            height="698px"
          />
          <map name="workmap">
            <area
              shape="rect"
              coords="238,257,300,380"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="318,257,382,380"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="399,257,463,380"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="558,288,678,356"
              alt=""
              href="https://www.amicogroup.com/specialization/orthopedic/products.aspx?id=6579"
              target="_blank"
            />
            <area
              shape="rect"
              coords="739 ,252,802,375"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link4)"
            />
            <area
              shape="rect"
              coords="820,252,883,375"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link5)"
            />
            <area
              shape="rect"
              coords="899,252,991,379"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link6)"
            />
          </map>
        </div>
        <div class="d_768">
          <img
            src="assets/event/exh/amico_booth_768.jpg"
            alt="Workplace"
            usemap="#workmap_768"
            width="768px"
            height="432px"
          />

          <map name="workmap_768">
            <area
              shape="rect"
              coords="149,157,188,235"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="199,159,239,235"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="248,159,288,235"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="345,177,420,220"
              alt=""
              href="https://www.amicogroup.com/specialization/orthopedic/products.aspx?id=6579"
              target="_blank"
            />
            <area
              shape="rect"
              coords="477 ,160,516,235"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link4)"
            />
            <area
              shape="rect"
              coords="527,160,566,235"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link5)"
            />
            <area
              shape="rect"
              coords="576,160,616,235"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link6)"
            />
          </map>
        </div>
        <div class="d_320">
          <img
            src="assets/event/exh/amico_booth_320.jpg"
            alt="Workplace"
            usemap="#workmap_320"
            width="320px"
            height="180px"
          />

          <map name="workmap_320">
            <area
              shape="rect"
              coords="62,66,79,98"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="82,66,99,98"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="103,66,120,98"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="143,74,175,91"
              alt=""
              href="https://www.amicogroup.com/specialization/orthopedic/products.aspx?id=6579"
              target="_blank"
            />
            <area
              shape="rect"
              coords="198 ,66,215,98"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link4)"
            />
            <area
              shape="rect"
              coords="219,66,235,98"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link5)"
            />
            <area
              shape="rect"
              coords="239,66,256,98"
              alt=""
              [routerLink]="['', 'exh-amico']"
              (click)="openDialog(link6)"
            />
          </map>
        </div>
      </div>
    </div>

    <!-- Container end -->
  </div>
</section>
