<!-- ts schedule start-->
<section class="ts-schedule">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <h2 class="section-title">
          <!-- <span>Schedule Details</span> -->
          Welcome Message
        </h2>
      </div>
      <!-- col end-->
    </div>
    <!-- row end-->

    <div class="row">
      <div class="col-md-12">
        <!-- <strong>Dear Colleagues and Friends,</strong><br /> -->
        The Egyptian Society for Surgery of the Hand and Microsurgery (ESSHM)
        has the pleasure to welcome you in its 14th international annual
        congress. Due to COVID 19 panademic, the meeting will be hybrid through
        onsite and online sessions. We are honored to have distinguished faculty
        from all over the world to share their valuable experience and knowledge
        with us. On behalf of ourselves and the organizing committee, we are
        very grateful to the participating faculty for their enthusiasm,
        dedication and generosity sharing this time with the ESSHM members and
        guests. We have the privilege to welcome the members of the Pan-Arab
        Federation of societies for surgery of the hand for the third time in
        our annual meeting.
        <br /><br />
        We aim to benefit from the high standard scientific content, discussing
        debatable issues, addressing highly specialized topics and applying
        different modalities of interactions. Our young surgeons will be
        competing for the second Nash Naam research award and the winners will
        be announced during the closing ceremony.<br /><br />
        We wish you all a successful meeting in the fascinating Cairo city with
        its extraordinary history and culture.
        <br /><br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/event/MostafaMahmoud.jpg"
              alt=""
            />
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title">
              <a>Mostafa Mahmoud, MD </a>
              <!-- <a href="#">{{ spe.name }} </a> -->
            </h3>
            <p>Conference President</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="ts-speaker">
          <div class="speaker-img">
            <img
              class="img-fluid"
              src="assets/event/MohamedEllaban.png"
              alt=""
            />
          </div>
          <div class="ts-speaker-info">
            <h3 class="ts-title">
              <a>Mohamed Ellaban </a>
              <!-- <a href="#">{{ spe.name }} </a> -->
            </h3>
            <p>Conference Secretary General</p>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
  <!-- container end-->
</section>
<!-- ts schedule end-->
