<section>
  <div class="container-fluid">
    <div class="row">
      <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
      <div class="col text-center">
        <div style="position: absolute">
          <a (click)="backClicked()" style="color: #fff"
            ><img
              src="/assets/event/exh/3d/white.svg"
              style="width: 26px; margin: 15px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a [routerLink]="['', 'audi']" style="color: #fff"
            ><img src="/assets/event/live1.gif" style="width: 80px"
          /></a>
          <!-- <a
            class="btn btn-success float-left"
            style="color: #fff; background-color: #28a745"
            href="https://api.whatsapp.com/send?phone=00971529115152"
            target="_blank"
            >Whatsapp
          </a> -->
        </div>
        <div class="d_1241">
          <img
            src="assets/event/exh/lilly_booth.jpg"
            alt="Workplace"
            usemap="#workmap"
            width="1241px"
            height="700px"
          />
          <map name="workmap">
            <area
              shape="rect"
              coords="750,253,850,433"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="616,253,717,433"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="471,253,579,433"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="329,253,439,433"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link4)"
            />
            <area
              shape="rect"
              coords="193,253,308,433"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link5)"
            />
            <!-- <area shape="rect" coords="323,265,453,338" alt="vedio" /> -->
          </map>
        </div>
        <div class="d_768">
          <img
            src="assets/event/exh/lilly_booth_768.jpg"
            alt="Workplace"
            usemap="#workmap_768"
            width="768px"
            height="433px"
          />

          <map name="workmap_768">
            <area
              shape="rect"
              coords="462,152,528,270"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link1)"
            />
            <area
              shape="rect"
              coords="379,152,445,270"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="290,152,358,270"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="201,152,274,270"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link4)"
            />
            <area
              shape="rect"
              coords="118,152,190,270"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link5)"
            />
          </map>
        </div>
        <div class="d_320">
          <img
            src="assets/event/exh/lilly_booth_320.jpg"
            alt="Workplace"
            usemap="#workmap_320"
            width="320px"
            height="180px"
          />

          <map name="workmap_320">
            <area
              shape="rect"
              coords="190,62,220,112"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link1)"
            />
            <area
              shape="rect"
              coords="157,62,186,112"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="121,62,149,112"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="83,62,114,112"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link4)"
            />
            <area
              shape="rect"
              coords="49,62,78,112"
              alt=""
              [routerLink]="['', 'exh-lilly']"
              (click)="openDialog(link5)"
            />
          </map>
        </div>
      </div>
    </div>

    <!-- Container end -->
  </div>
</section>
