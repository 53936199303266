<div class="countdown">
  <div class="counter-item">
    <span class="days">{{ days }}</span>
    <div class="smalltext">Days</div>
  </div>
  <div class="counter-item">
    <span class="hours">{{ hours }}</span>
    <div class="smalltext">Hours</div>
  </div>
  <div class="counter-item">
    <span class="minutes">{{ mins }}</span>
    <div class="smalltext">Minutes</div>
  </div>
  <div class="counter-item">
    <span class="seconds">{{ secs }}</span>
    <div class="smalltext">Seconds</div>
  </div>
</div>
