import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'app/core/http/api.service';
import { environment as env } from '@env/environment';

@Component({
  selector: 'app-society',
  templateUrl: './society.component.html',
  styleUrls: ['./society.component.css'],
})
export class SocietyComponent implements OnInit {
  @Input() conference: any;
  @Input() con_id: String = '';

  speakers: any;
  imageLink: string;
  constructor(private apiServerice: ApiService) {
    this.imageLink = env.imageLink;
  }

  ngOnInit(): void {
    // console.log('ya rab');
    // console.log('con_id', this.con_id);
    // console.log(this.conference);
    // console.log(this.speakers);
    this.doGet();
  }

  doGet() {
    // console.log('Besm allah ');
    // console.log(`conferences/${this.conference.id}`);
    this.apiServerice
      .doGet(`conferences/${env.selectedEvent}/roles/6/members`)
      .subscribe((res) => {
        // console.log('Besm allah Alrahman');
        // console.log('res', res);
        this.speakers = res;
      });
  }
}
