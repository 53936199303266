<section>
  <div class="container-fluid">
    <div class="row">
      <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
      <div class="col text-center">
        <div style="position: absolute">
          <a (click)="backClicked()" style="color: #fff"
            ><img
              src="/assets/event/exh/3d/white.svg"
              style="width: 26px; margin: 15px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a [routerLink]="['', 'audi']" style="color: #fff"
            ><img src="/assets/event/live1.gif" style="width: 80px"
          /></a>
          &nbsp;&nbsp;&nbsp;
          <a
            href="https://amgen.webex.com/amgen/j.php?MTID=m8d93c6ee2cf39c3d37077da0d966d2d4"
            target="_blank"
            ><img
              src="/assets/event/videocall.png"
              style="width: 60px; padding: 10px"
            />
          </a>
        </div>
        <div class="d_1241">
          <img
            src="assets/event/exh/amgen_booth.jpg"
            alt="Workplace"
            usemap="#workmap"
            width="1241px"
            height="700px"
          />
          <map name="workmap">
            <area
              shape="rect"
              coords="88,285,191,484"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="289,279,499,383"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="574,281,786,388"
              href="assets/event/exh/amgen_5.Evenity-ITC-lightbox-158x80V3.pdf"
              target="_blank"
            />
            <area
              shape="rect"
              coords="846,289,960,355"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="1039,285,1150,842"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link4)"
            />

            <!-- <area shape="rect" coords="323,265,453,338" alt="vedio" /> -->
          </map>
        </div>
        <div class="d_768">
          <img
            src="assets/event/exh/amgen_booth_768.jpg"
            alt="Workplace"
            usemap="#workmap_768"
            width="768px"
            height="433px"
          />

          <map name="workmap_768">
            <area
              shape="rect"
              coords="52,173,122,300"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="178,172,309,237"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="356,173,485,238"
              href="assets/event/exh/amgen_5.Evenity-ITC-lightbox-158x80V3.pdf"
              target="_blank"
            />
            <area
              shape="rect"
              coords="523,178,594,219"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="642,175,710,296"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link4)"
            />
          </map>
        </div>
        <div class="d_320">
          <img
            src="assets/event/exh/amgen_booth_320.jpg"
            alt="Workplace"
            usemap="#workmap_320"
            width="320px"
            height="180px"
          />

          <map name="workmap_320">
            <area
              shape="rect"
              coords="21,72,50,123"
              alt="sss"
              href="#"
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link1)"
            />
            <!-- [routerLink]="['', 'show-image', link1]" -->
            <area
              shape="rect"
              coords="74,71,129,99"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link2)"
            />
            <area
              shape="rect"
              coords="148,72,202,99"
              href="assets/event/exh/amgen_5.Evenity-ITC-lightbox-158x80V3.pdf"
              target="_blank"
            />
            <area
              shape="rect"
              coords="217,73,248,91"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link3)"
            />
            <area
              shape="rect"
              coords="266,72,297,124"
              alt=""
              [routerLink]="['', 'exh-amgen']"
              (click)="openDialog(link4)"
            />
          </map>
        </div>
      </div>
    </div>

    <!-- Container end -->
  </div>
</section>
